import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { Helmet } from "react-helmet"

export interface SeoProps {
  /**
   * Page title.
   */
  title: string

  /**
   * Page description.
   *
   * @default Site description.
   */
  description?: string

  /**
   * Relative path to the image for og:image.
   *
   * @default Site image.
   */
  image?: string

  /**
   * Extra meta tags for the document <head />.
   */
  meta?: Record<string, string>[]
}

/**
 * Site SEO and other <head /> content.
 */
const Seo = ({ title, description, image, meta = [] }: SeoProps) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            url
            image
          }
        }
      }
    `
  )

  const defaultTitle = siteMetadata.title
  const metaDescription = description || siteMetadata.description
  const metaImage = `${siteMetadata.url}/${image || siteMetadata.image}`

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s - ${defaultTitle}` : undefined}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "google-site-verification",
          content: "FeIRLzmTxtW3AGbqahwV9piTVFSur46S8YbC0gpbF3s",
        },
        ...meta,
      ]}
    />
  )
}

export default Seo
