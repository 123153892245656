/**
 * Global site configuration.
 * No current support for per-environment configuration as it is not needed.
 */
const config = {
  /**
   * When the viewport reaches each value, the key becomes the active breakpoint.
   * Should match the values in @see /src/styles/settings/_settings.general
   */
  bootstrapBreakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
    xxxl: 1920,
  },

  /**
   * Link to download the GritLife app from the Apple App Store.
   */
  appStoreLink: "https://apps.apple.com/us/app/gritlife/id1584050998",

  /**
   * Link to download the GritLife app from the Google Play Store.
   */
  googlePlayLink: "https://play.google.com/store/apps/details?id=app.gritlife.mobile",

  /**
   * Link to the GritLife Instagram page.
   */
  instagramLink: "https://www.instagram.com/gritlife_app/",

  /**
   * Link to the GritLife Facebook page.
   */
  facebookLink: "https://www.facebook.com/GritLifeAU/",

  /**
   * Link to the GritLife Twitter page.
   */
  twitterLink: "https://twitter.com/gritlifeapp",
}

export default config
