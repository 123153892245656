import clsx from "clsx"
import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import { Button, Container, Navbar } from "react-bootstrap"
import LogoSvg from "../../images/logo.svg"
import * as styles from "./Header.module.scss"
import OffscreenNav from "./OffscreenNav"
import OffscreenNavToggleButton from "./OffscreenNavToggleButton"
import SiteMenu from "./SiteMenu"

export interface HeaderProps {
  /**
   * Color scheme - dark uses black logo and text, light uses white logo and text.
   */
  logoColor: "dark" | "light"

  /**
   * Color scheme - dark uses black logo and text, light uses white logo and text.
   */
  menuColor: "dark" | "light"

  style?: React.CSSProperties

  className?: string

  /**
   * Callback when mobile Offscreen nav is opened/closed.
   */
  onToggleOffscreenNav?(isOpen: boolean): void

  /**
   * Event handler for when the GritLife logo is clicked.
   */
  onClickLogo?(event: React.MouseEvent<HTMLAnchorElement>): void

  /**
   * Event handler for when the "Download Now" CTA is clicked.
   */
  onClickDownload?(event: React.MouseEvent<HTMLAnchorElement>): void
}

const Header = ({
  logoColor,
  menuColor,
  style,
  className,
  onToggleOffscreenNav,
  onClickLogo,
  onClickDownload,
}: HeaderProps) => {
  const [offscreenNavOpen, setOffscreenNavOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const siteTitle = data.site.siteMetadata?.title

  const handleToggleOffscreenNav = (isOpen: boolean) => {
    if (onToggleOffscreenNav) {
      onToggleOffscreenNav(isOpen)
    }

    setOffscreenNavOpen(isOpen)
  }

  return (
    <Navbar
      expand="md"
      bg="transparent"
      // Invert the variant to match react-bootstrap's idea of dark/light.
      variant={menuColor === "dark" ? "light" : "dark"}
      className={clsx(styles.siteHeader, className)}
      style={style}
    >
      <Container fluid>
        <Navbar.Brand
          as={Link}
          title="Return to homepage"
          to="/"
          onClick={onClickLogo}
        >
          <LogoSvg
            alt={siteTitle}
            className={clsx(
              styles.siteLogo,
              logoColor === "light" && styles.siteLogoLight
            )}
          />
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="site-offcanvas-nav"
          className={styles.offcanvasToggle}
        >
          <OffscreenNavToggleButton open={offscreenNavOpen} />
        </Navbar.Toggle>

        <div className="d-none d-md-flex align-items-center">
          <SiteMenu variant="desktop" />

          <Button
            href="#site-footer"
            variant="success"
            className={styles.downloadButton}
            onClick={onClickDownload}
          >
            Download now
          </Button>
        </div>

        <OffscreenNav onToggle={handleToggleOffscreenNav} />
      </Container>
    </Navbar>
  )
}

export default Header
