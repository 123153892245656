import * as React from "react"
import { Navbar, Offcanvas } from "react-bootstrap"
import AppStoreBadges from "../AppStoreBadges"
import BackgroundGradients from "../BackgroundGradients"
import * as styles from "./OffscreenNav.module.scss"
import SiteMenu from "./SiteMenu"

export interface OffscreenNavProps {
  /**
   * Callback when nav is opened/closed.
   */
  onToggle?(isVisible: boolean): void
}

const OffscreenNav = ({ onToggle }: OffscreenNavProps) => {
  const handleShow = () => {
    if (onToggle) {
      onToggle(true)
    }
  }

  const handleExited = () => {
    if (onToggle) {
      onToggle(false)
    }
  }

  return (
    <Navbar.Offcanvas
      id="site-offcanvas-nav"
      placement="start"
      backdrop={false}
      onShow={handleShow}
      onExited={handleExited}
      className={styles.offcanvas}
    >
      <Offcanvas.Body className={styles.body}>
        <SiteMenu variant="mobile" className={styles.menu} />

        <AppStoreBadges />
      </Offcanvas.Body>

      <BackgroundGradients noise className={styles.backgroundGradients}>
        <div className={styles.pink1}></div>
        <div className={styles.yellow1}></div>
      </BackgroundGradients>
    </Navbar.Offcanvas>
  )
}

export default OffscreenNav
