import clsx from "clsx"
import React from "react"
import * as styles from "./OffscreenNavToggleButton.module.scss"

export interface OffscreenNavToggleButtonProps {
  /**
   * Is the offscreen nav open?
   */
  open: boolean
}

const OffscreenNavToggleButton = ({ open }: OffscreenNavToggleButtonProps) => {
  return (
    <div className={clsx(styles.menu, open && styles.open)}>
      <div className={styles.bar} />
      <div className={styles.bar} />
      <div className={styles.bar} />
    </div>
  )
}

export default OffscreenNavToggleButton
