import clsx from "clsx"
import { Link } from "gatsby"
import React from "react"
import { Nav } from "react-bootstrap"
import * as styles from "./SiteMenu.module.scss"

interface SiteNav {
  /**
   * Horizontal "desktop" menu for use in NavBar, or the vertical "mobile" menu
   * for use in Offscreen.
   */
  variant: "desktop" | "mobile"

  className?: string
}

/**
 * Primary site menu links. Supports the horizontal desktop menu, and the vertical
 * offscreen mobile menu.
 */
const SiteMenu = ({ variant, className }: SiteNav) => {
  return (
    <Nav
      className={clsx(
        styles.siteMenu,
        variant === "mobile" && styles.siteMenuMobile,
        className
      )}
    >
      <Nav.Link
        as={Link}
        to="/our-story/"
        activeClassName="active"
        partiallyActive
      >
        Our story
      </Nav.Link>
    </Nav>
  )
}

export default SiteMenu
