import clsx from "clsx"
import { Link } from "gatsby"
import * as React from "react"
import { Container, Nav } from "react-bootstrap"
import config from "../../config"
import AppStoreBadges from "../AppStoreBadges"
import * as styles from "./Footer.module.scss"

export interface FooterProps {
  className?: string
}

const Footer = ({ className }: FooterProps) => (
  <footer id="site-footer" className={clsx(styles.siteFooter, className)}>
    <Container fluid>
      <div className={styles.downloadCta}>
        <p className="lead d-none d-sm-block mb-4">GritLife is now in beta</p>
        <h3 className="mb-3">Download our app today</h3>

        <AppStoreBadges />
      </div>

      <div className="d-flex flex-column align-items-center text-start">
        <div className={styles.footerNavWrapper}>
          <div className={styles.footerNav}>
            <Nav
              className={clsx(
                "flex-column mb-0 order-md-3",
                styles.supplementaryMenu
              )}
            >
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/our-story/">
                Our story
              </Nav.Link>
              <Nav.Link as={Link} to="/privacy-policy/">
                Privacy policy
              </Nav.Link>
              <Nav.Link as={Link} to="/terms/">
                Terms of service
              </Nav.Link>
            </Nav>

            <div className="order-md-1">
              <h6 className="text-uppercase">Follow us on:</h6>

              <Nav className={clsx("flex-column mb-0", styles.socialMenu)}>
                <Nav.Link
                  href={config.instagramLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </Nav.Link>
                <Nav.Link
                  href={config.facebookLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </Nav.Link>
                <Nav.Link
                  href={config.twitterLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </Nav.Link>
              </Nav>
            </div>

            <div className="order-md-2">
              <h6 className="text-uppercase">Say hi:</h6>
              <a href="mailto:hello@gritlife.app">hello@gritlife.app</a>
            </div>
          </div>

          <p className="mb-0">&copy;{new Date().getFullYear()} GritLife</p>
        </div>
      </div>
    </Container>
  </footer>
)

export default Footer
