import clsx from "clsx"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Nav } from "react-bootstrap"
import config from "../../config"
import * as styles from "./AppStoreBadges.module.scss"
import AppStoreBadgeSvg from "./app-store-badge.svg"

export interface AppStoreBadgesProps {
  /**
   * Horizontally align badges. "start"/"end" removes the button edge padding to
   * keep it flush with the container.
   * @default "center"
   */
  align?: "start" | "center" | "end"

  className?: string
}

/**
 * App store (Apple + Google) badges with links to download the app. Horizontally
 * aligned on desktop, vertically stacked on mobile.
 */
const AppStoreBadges = ({
  align = "center",
  className,
}: AppStoreBadgesProps) => {
  let alignmentClasses = ""

  if (align === "start") {
    alignmentClasses = clsx(
      "flex-sm-row align-items-sm-center",
      styles.alignStart
    )
  } else if (align === "center") {
    alignmentClasses =
      "flex-sm-row justify-content-sm-center align-items-sm-center"
  } else if (align === "end") {
    alignmentClasses = clsx(
      "flex-sm-row justify-content-sm-end align-items-sm-center",
      styles.alignRight
    )
  }

  return (
    <Nav className={clsx(styles.appStoreBadges, alignmentClasses, className)}>
      <Nav.Link href={config.googlePlayLink}>
        <StaticImage
          src="../../images/google-play-badge.png"
          alt="Get it on Google Play"
          loading="eager"
          layout="fixed"
          placeholder="none"
          width={181}
          height={54}
        />
      </Nav.Link>

      <Nav.Link href={config.appStoreLink}>
        <AppStoreBadgeSvg
          alt="Download on the App Store"
          width={163}
          height={54}
        />
      </Nav.Link>
    </Nav>
  )
}

export default AppStoreBadges
