import clsx from "clsx"
import React from "react"
import { capitalizeFirstLetter } from "../../utils"
import * as styles from "./BackgroundGradients.module.scss"

export interface BackgroundGradientsProps {
  children: React.ReactNode

  /**
   * Add noise overlay to the gradients.
   */
  noise?: boolean

  /**
   * Intensity and boldness of the gradient. Achieves this via the blur radius.
   *
   * @default "medium"
   */
  intensity?: "low" | "medium" | "high"

  className?: string
}

const BackgroundGradients = ({
  children,
  noise = false,
  intensity = "medium",
  className,
}: BackgroundGradientsProps) => {
  const intensityStyle =
    styles[
      `intensity${
        capitalizeFirstLetter(intensity) as "Low" | "Medium" | "High"
      }`
    ]

  return (
    <div
      className={clsx(
        styles.backgroundGradients,
        noise && styles.noise,
        intensityStyle,
        className
      )}
    >
      {children}
    </div>
  )
}

export default BackgroundGradients
