import "@fontsource/permanent-marker"
import clsx from "clsx"
import React, { useState } from "react"
import "../../styles/app.scss"
import Footer from "./Footer"
import Header from "./Header"
import * as styles from "./Layout.module.scss"

export interface LayoutProps {
  children: React.ReactNode

  /**
   * Display light or dark colored logo.
   * May be overridden under certain conditions like the homepage desktop scrolling,
   * or when offscreen nav is open.
   */
  logoColor?: "dark" | "light"

  /**
   * Display light or dark colored menu links.
   */
  menuColor?: "dark" | "light"

  /**
   * Normally the menu sits on top of the page content. This pushes the page content
   * down to the bottom of the menu.
   */
  pushTop?: boolean

  /**
   * Style specific parts of the component with classNames.
   */
  classes?: Record<string, string>

  className?: string
}

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
const Layout = ({
  children,
  logoColor = "dark",
  menuColor = "dark",
  pushTop,
  classes = {},
  className,
}: LayoutProps) => {
  const [offscreenNavOpen, setOffscreenNavOpen] = useState(false)

  // When offscreen nav is open, the logo should always be white for better contrast.
  const finalLogoColor = offscreenNavOpen ? "light" : logoColor

  const handleToggleOffscrenNav = (isOpen: boolean) => {
    setOffscreenNavOpen(isOpen)
  }

  return (
    <div className={className}>
      <Header
        logoColor={finalLogoColor}
        menuColor={menuColor}
        onToggleOffscreenNav={handleToggleOffscrenNav}
        className={classes.header}
      />

      <main
        className={clsx(
          styles.content,
          pushTop && styles.pushTop,
          classes.content
        )}
      >
        {children}
      </main>

      <Footer className={classes.footer} />
    </div>
  )
}

export default Layout
